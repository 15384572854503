import React from 'react';

const style = {
    background: 'transparent',
    color: 'black',
    border: '1px solid black'
};

const SizePicker = ({ changeLineSize }) => {
    return (
        <>
            <button
                className="roundel-button"
                style={style}
                onClick={() => changeLineSize(1)}
            >+</button>
            <button
                className="roundel-button"
                style={style}
                onClick={() => changeLineSize(-1)}
            >-</button>
        </>

    );
}

export default SizePicker;