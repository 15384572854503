import React, { createRef } from 'react';

import './App.css';
import { Draw } from './Draw';
import { COLOR } from './colors';
import ColorPicker from './ColorPicker';
import SizePicker from './SizePicker';
import LoadingButton from './LoadingButton';
import fscreen from 'fscreen';

const GET_URL = 'https://api.inky.pictures/current?key=badger&format=original';
const UPLOAD_URL = "https://api.inky.pictures/current?key=badger";

const WIDTH = 212;
const HEIGHT = 104;

let c = document.createElement('canvas');
c.width = WIDTH;
c.height = HEIGHT;
let ctx = c.getContext('2d');

const fetchImage = async () => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => resolve(img);
    img.onerror = reject;
    // img.src = 'https://i.imgur.com/z2Fj9sH.jpg';
    img.src = GET_URL;
  });
}

class InkyApp extends React.Component {

  state = {
    color: COLOR.red,
    lineSize: 5,
    loaded: false,
    isFull: false,
    isPublishing: false,
    dirty: false,
  };

  constructor(props) {
    super(props);

    this.fsElement = createRef(null);
  }

  componentDidMount() {
    fetchImage().then(img => {
      ctx.drawImage(img, 0, 0);
      this.setState({ loaded: true });
    });
  }

  getImageState = () => ctx.getImageData(0, 0, WIDTH, HEIGHT);

  setImageState = (state) => {
    ctx.putImageData(state, 0, 0);
    this.setState({ dirty: true });
  };

  storeImage = async () => {
    this.setState({ isPublishing: true });
    let blob = await (
      new Promise((resolve) => c.toBlob(resolve, "image/png", 1.0))
    );
    let formData = new FormData();
    formData.append('inkyfile', blob, 'inkyfile.png');
    let result = await fetch(UPLOAD_URL, {
      method: 'PUT',
      body: formData
    })
    this.setState({ isPublishing: false, dirty: false });
    return result;
  }

  setColor = c => this.setState({ color: c })

  updateLineSize = d => this.setState({
    lineSize: (Math.max(1, this.state.lineSize + d))
  })

  toggleFullscreen = () => {
    if (fscreen.fullscreenElement) {
      fscreen.exitFullscreen();
      return this.setState({ isFull: false });
    }

    fscreen.requestFullscreen(this.fsElement.current);
    return this.setState({ isFull: true });
  }

  render() {
    let {
      loaded,
      color,
      lineSize,
      isPublishing,
      dirty
    } = this.state;

    if (!loaded) {
      return <h2 id="loading">loading ...</h2>;
    }

    return (
      <div id="main" ref={this.fsElement}>
        <div id="drawspace">
          <Draw
            color={color}
            lineSize={lineSize}
            getState={() => this.getImageState()}
            setState={(state) => this.setImageState(state)}
          ></Draw>
        </div>
        <div id="buttons">
          <span className="controls">
            <ColorPicker selected={color === COLOR.black} bg={COLOR.black} onSelect={() => this.setColor(COLOR.black)} />
            <ColorPicker selected={color === COLOR.red} bg={COLOR.red} onSelect={() => this.setColor(COLOR.red)} />
            <ColorPicker selected={color === COLOR.white} bg={COLOR.white} onSelect={() => this.setColor(COLOR.white)} />
            <SizePicker changeLineSize={this.updateLineSize} />
          </span>
          <span className="controls">
            <button
              className="button"
              onClick={this.toggleFullscreen}
              disabled={!fscreen.fullscreenEnabled}
            >Toggle Full Screen</button>
            <LoadingButton
              onSubmit={() => this.storeImage()}
              isLoading={isPublishing}
              enabled={dirty}
            />
          </span>
        </div>
      </div>
    );
  }
}

export default InkyApp;
