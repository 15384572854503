import React from "react";

const Loader = () => <div className="loader" />;

const LoadingButton = ({ isLoading, onSubmit, enabled }) => {
    return (
        <button className="button" onClick={onSubmit} disabled={!enabled}>
            {isLoading ? <Loader /> : "Publish!"}
        </button>
    );
};

export default LoadingButton;