import React from 'react';

const Color = ({ bg, onSelect, selected }) => {
    return <button
        className="roundel-button"
        style={{
            backgroundColor: bg,
            border: selected ? '1px solid white' : '1px solid black'
        }}
        onClick={onSelect}
    ></button>
};

export default Color;