
import React, { createRef } from 'react';

const CLICK_ID = 1123;

export class Draw extends React.Component {
    constructor(props) {
        super(props);
        this.cRef = createRef(null);
        this.ctxRef = null;
        this.lines = {};
    }

    canvas() {
        return this.cRef.current;
    }

    ctx() {
        if (this.ctxRef != null) return this.ctxRef;

        this.ctxRef = this.canvas().getContext('2d');
        this.ctxRef.imageSmoothingEnabled = false;
        return this.ctxRef;
    }

    componentDidMount() {
        this.ctx().putImageData(this.props.getState(), 0, 0);
    }

    componentWillUnmount() {
        this.props.setState(this.getImageData());
    }

    getImageData() {
        let canvas = this.canvas();
        return this.ctx().getImageData(0, 0, canvas.width, canvas.height);
    }

    coords(e) {
        let canvas = this.canvas();
        let { left, top, width, height } = canvas.getBoundingClientRect();

        return {
            x: (e.clientX - left) * canvas.width / width,
            y: (e.clientY - top) * canvas.height / height
        };
    }

    drawStart(id, position) {
        this.lines[id] = position;

        let ctx = this.ctx();
        ctx.fillStyle = this.props.color;
        ctx.beginPath();
        ctx.arc(position.x, position.y, this.props.lineSize / 2, 0, Math.PI * 2);
        ctx.fill();
    }

    drawMove(id, position) {
        let ctx = this.ctx();
        ctx.strokeStyle = this.props.color;
        ctx.lineWidth = this.props.lineSize;

        let { x, y } = this.lines[id] || position;
        ctx.beginPath();
        ctx.arc(position.x, position.y, this.props.lineSize / 2, 0, Math.PI * 2);
        ctx.fill();

        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(position.x, position.y);
        ctx.stroke();
        this.lines[id] = position;
    }

    drawEnd(id, position) {
        this.drawMove(id, position);
        delete this.lines[id];
        this.props.setState(this.getImageData());
    }

    render() {
        return (
            <canvas
                ref={this.cRef}
                id="draw"
                width={212}
                height={104}
                onTouchStart={
                    e => this.drawStart(
                        e.changedTouches[0].identifier,
                        this.coords(e.changedTouches[0])
                    )
                }
                onTouchMove={
                    e => {
                        let ts = e.changedTouches;
                        for (let i = 0; i < ts.length; i++) {
                            this.drawMove(
                                ts[i].identifier,
                                this.coords(ts[i])
                            );
                        }
                    }
                }
                onTouchEnd={
                    e => {
                        if (e.changedTouches.length === 0) { // ff bug
                            return this.drawEnd(
                                0, { x: 0, y: 0 }
                            );
                        }
                        this.drawEnd(
                            e.changedTouches[0].identifier,
                            this.coords(e.changedTouches[0])
                        );
                    }
                }
                onMouseDown={
                    e => this.drawStart(CLICK_ID, this.coords(e))

                }
                onMouseMove={
                    e => (e.buttons === 1) && this.drawMove(CLICK_ID, this.coords(e))
                }
                onMouseUp={
                    e => {
                        this.drawEnd(CLICK_ID, e);
                    }
                }
            ></canvas >
        );
    }
}
